import { Factory } from 'miragejs';
import moment from 'moment';
import { randomValue, randomInteger } from '../utils';

export default () => Factory.extend({
  created_at: () => moment().add(randomInteger(-30, 0), 'd').toISOString(),
  start_date: () => moment().add(randomInteger(0, 2), 'd').toISOString(),
  end_date: () => moment().add(randomInteger(0, 2), 'd').toISOString(),
  checkin_time: () => null,
  checkin_type: () => null,
  checkin_confirmed_by_employee: () => null,
  pickup_completed_by_employee: () => null,
  preparation_completed_by_employee: () => null,
  preparation_status: () => randomValue(['not_prepared', 'ready_for_pickup']),
  barcode: () => randomValue([null, 'HY12345667']),
  name: (i) => `Reservation ${i}`,
  price: () => {
    /* eslint-disable camelcase */
    const total_gear = randomInteger(0, 120);
    const total_tickets = randomInteger(0, 120);

    return {
      total_gear,
      total_tickets,
      total_price: total_gear + total_tickets,
      currency: 'CHF',
    };
  },
});
