import {
  createServer, Model, hasMany, belongsTo, Serializer,
} from 'miragejs';

import routes from './routes';
import seeds from './seeds';
import ReservationFactory from './factories/reservation';
import ReservationProfileFactory from './factories/reservation-profile';

const ApplicationSerializer = Serializer.extend({
  root: false,
  embed: true,
});

export default () => createServer({
  models: {
    reservation: Model.extend({
      profiles: hasMany('reservationProfile'),
    }),
    reservationProfile: Model.extend({
      reservation: belongsTo(),
    }),
  },
  serializers: {
    application: ApplicationSerializer,
    reservationList: ApplicationSerializer.extend({
      include: ['profiles'],
      root: true,
    }),
    reservation: ApplicationSerializer.extend({
      include: ['profiles'],
    }),
    reservationProfile: ApplicationSerializer.extend({
      attrs: ['id', 'preparation_status'],
    }),
    reservationProfileExtended: ApplicationSerializer.extend(),
  },
  factories: {
    reservation: ReservationFactory(),
    reservationProfile: ReservationProfileFactory(),
  },
  routes,
  seeds,
});
