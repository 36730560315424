import { randomInteger } from '@/api/mocks/utils';

export default function (server) {
  server.createList('reservation', 8).forEach((reservation) => {
    server.createList('reservationProfile', randomInteger(1, 4), { reservation }).forEach((profile) => {
      if (profile.ticket && !profile.ticket.card) {
        profile.update({
          preparation_status: 'additional_data_needed',
        });
      }
    });

    // Update Name
    const snapshot = reservation.profiles.models[0].profile_snapshot;
    const name = `${snapshot.first_name} ${snapshot.last_name}`;
    reservation.update({ name });
  });
}
