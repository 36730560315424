/* eslint-disable func-names */
import moment from 'moment';
import { Response } from 'miragejs';
import { randomInteger } from '@/api/mocks/utils';

export default function () {
  this.urlPrefix = process.env.VUE_APP_API_BASE_URL;
  this.namespace = '/api/employee';

  this.post('/login/', {
    title: 'Test Booth',
    token: 'X',
  });

  this.get('/reservations/', function (schema) {
    const reservations = schema.reservations.where({
      pickup_completed_by_employee: null,
    });
    return this.serialize(reservations, 'reservation-list');
  });

  this.get('/reservations/:id');

  this.patch('/reservations/:id/mark_ready', function (schema, request) {
    const reservation = schema.reservations.find(request.params.id);
    reservation.update({
      preparation_status: 'ready_for_pickup',
      preparation_completed_by_employee: moment().toISOString(),
    });
    return this.serialize(reservation);
  });

  this.patch('/reservations/:id/manual_checkin', function (schema, request) {
    const reservation = schema.reservations.find(request.params.id);
    reservation.update({
      checkin_type: 'manual',
      checkin_time: moment().toISOString(),
    });
    return this.serialize(reservation);
  });

  this.patch('/reservations/:id/qr_checkin', function (schema, request) {
    const reservation = schema.reservations.find(request.params.id);
    reservation.update({
      checkin_type: 'qr_code',
      checkin_time: moment().toISOString(),
    });
    return this.serialize(reservation);
  });

  this.patch('/reservations/:id/confirm_checkin', function (schema, request) {
    const reservation = schema.reservations.find(request.params.id);
    reservation.update({
      checkin_confirmed_by_employee: moment().toISOString(),
    });
    return this.serialize(reservation);
  });

  this.patch('/reservations/:id/complete_pickup', function (schema, request) {
    const reservation = schema.reservations.find(request.params.id);
    reservation.update({
      pickup_completed_by_employee: moment().toISOString(),
    });
    return this.serialize(reservation);
  });

  this.get('/reservation-profiles/:id', function (schema, request) {
    const profile = schema.reservationProfiles.find(request.params.id);
    return this.serialize(profile, 'reservation-profile-extended');
  });

  this.patch('/reservation-profiles/:id/mark_ready', function (schema, request) {
    const profile = schema.reservationProfiles.find(request.params.id);
    profile.update({
      preparation_status: 'ready_for_pickup',
    });
    return this.serialize(profile, 'reservation-profile-extended');
  });

  this.patch('/reservation-profiles/:id/add_card', function (schema, request) {
    const profile = schema.reservationProfiles.find(request.params.id);
    const ticketcornerNumber = JSON.parse(request.requestBody).ticketcorner_number;

    if (ticketcornerNumber.length < 11) {
      return new Response(403, {}, {
        message: 'Invalid Skicard ID',
      });
    }

    profile.update({
      ticket: {
        price: randomInteger(50, 100),
        number_of_days: randomInteger(1, 5),
        display_name: 'Skipass',
        ticket_description: '3-day adult pass',
        card: {
          card_number: ticketcornerNumber,
          card_type_display_name: 'HYLL Card',
        },
      },
    });

    return this.serialize(profile, 'reservation-profile-extended');
  });
}
