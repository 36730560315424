import { Factory } from 'miragejs';
import { randomDate, randomInteger, randomValue } from '@/api/mocks/utils';

const firstNames = ['Daniel', 'Peter', 'Thomas', 'Pascal', 'Michael', 'Martin', 'Maria', 'Anna', 'Sandra', 'Sofie', 'Ursula', 'Ruth'];
const lastNames = ['Müller', 'Meier', 'Schmid', 'Keller', 'Gerber', 'Weber', 'Schneider', 'Huber', 'Meyer', 'Steiner', 'Favre', 'Frei'];

export default () => Factory.extend({
  preparation_status: () => 'not_prepared',
  activity: () => ({ id: 1, name: 'Skiing' }),
  // barcode: () => String(randomInteger(1213444, 9785552)),
  profile_snapshot: (id) => ({
    id,
    age: randomValue(12, 70),
    date_of_birth: randomDate(1920, 2004),
    first_name: randomValue(firstNames),
    last_name: randomValue(lastNames),
    weight: randomInteger(10, 150),
    height: randomInteger(60, 210),
    shoe_size: randomInteger(18, 50),
    shoe_sole_length: randomInteger(100, 400),
    foot_position: randomValue(['normal', 'goofy']),
    level: randomValue(['beginner', 'advanced', 'pro']),
    gender: randomValue(['male', 'female', 'other']),
  }),
  reservation_items: () => [
    {
      booth_item_id: 27,
      gear_type: 'Ski',
      gear_subtype: 'Allmountain',
      price_category: 'Standard',
      price: 42.0,
    },
    {
      gear_type: 'Helmet',
      gear_subtype: null,
      price_category: 'Premium',
      price: 21.0,
    },
  ],
  ticket: () => randomValue([
    null,
    {
      price: randomInteger(50, 100),
      number_of_days: randomInteger(1, 5),
      display_name: 'Skipass',
      ticket_description: '3-day adult pass',
      card: randomValue([
        null,
        {
          ticketcorner_number: randomValue([null, '123456789']),
          card_type_display_name: 'HYLL Card',
        },
      ]),
    },
  ]),
});
